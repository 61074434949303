.slider-section {
  padding: 8.8rem 0;
}
.slider-section .slider-section--headline {
  font-size: var(--fz-xs-h1);
  color: var(--white-color);
}
.slider-section .slider-section--description {
  color: var(--white-color);
}

@media (min-width: 992px) {
  .slider-section {
    padding: 11.8rem 0;
  }
}